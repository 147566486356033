import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, Image, ListItemWithImage, StatusBadge, Text } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import Icons from '@components/Icons'

const ListView = (props) => {
  const { file } = props

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  return (
    <ListItemWithImage imageComponent={(
      <Box
        alignItems="center"
        as={file?.download_uri ? 'a' : null}
        borderRight={['none', '1px solid']}
        borderBottom={['1px solid', 'none']}
        borderColor={['lineColor', 'lineColor']}
        href={`${file?.download_uri}`}
        justifyContent="center"
        padding="medium"
        style={{ textDecoration: 'none' }}
        width={['100%', 'fit-content']}
        target="_blank"
      >
        <Image
          boxProps={{ backgroundColor: 'hoverLightGrey' }}
          borderRadius={5}
          height={[180, '100%']}
          url={file?.download_uri || ''}
          width={['100%', 200]}
        >
          {file?.content_type === 'application/pdf' && (
            <Box
              alignItems="center"
              color="bodyFontColor"
              display="grid"
              justifyContent="center"
              width="100%"
            >
              <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
            </Box>
          )}
        </Image>
      </Box>
    )}
    >
      <ListItemWithImage.Header>
        <ListItemWithImage.HeaderLeft>
          <ListItemWithImage.HeaderText>
            {file?.metadata?.name || file.name}
          </ListItemWithImage.HeaderText>
        </ListItemWithImage.HeaderLeft>
      </ListItemWithImage.Header>
      <ListItemWithImage.Footer>
        <ListItemWithImage.FooterLeft boxProps={{ maxWidth: '75%!important' }}>
          <Box fontSize="small" marginBottom="medium" alignItems="center">
            <StatusBadge
              color="mysteryGrey"
              text={file?.content_type}
            />
          </Box>
          <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis" width={500}>
            {file?.metadata?.notes || ''}
          </Text>
        </ListItemWithImage.FooterLeft>
      </ListItemWithImage.Footer>
    </ListItemWithImage>
  )
}

ListView.propTypes = {
  file: PropTypes.object.isRequired,
}

export default ListView
